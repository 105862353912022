import React, { useState } from "react";
import style from "./DropdownWithButtons.module.scss";
import sort from "../../../assets/sort.svg";
import close from "../../../assets/close.svg";

export default function DropdownWithButtons({
  setSelectedCategories,
  selectedCategories,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customCategory, setCustomCategory] = useState("");
  const [highlightedCategories, setHighlightedCategories] = useState([
    customCategory,
  ]);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [inputBackgroundColor, setInputBackgroundColor] = useState("#FAFAFA");
  const [inputBorderStyle, setInputBorderStyle] = useState(
    "1px solid rgba(0, 0, 0, 0.05)"
  );
  const [inputBorderColor, setInputBorderColor] = useState("#27A2DC");

  const categories = [
    "Dongfeng",
    "Sitrak",
    "Shacman",
    "Faw",
    "Foton",
    "Valdai",
    "Volvo",
    "Renault",
    "Scania",
    "Mercedes",
    "Man",
    "Saic",
  ];

  const handleCategoryClick = (category) => {
    if (category !== "Другой" && !selectedCategories.includes(category)) {
      setSelectedCategories([...selectedCategories, category]);
      setHighlightedCategories([...highlightedCategories, category]);
      setShowCustomInput(false);
      setInputBackgroundColor("#FFF");
      setInputBorderStyle("1px solid #27A2DC");
      setInputBorderColor("#27A2DC");
    } else {
      setShowCustomInput(false);
      setInputBackgroundColor("#FAFAFA");
      setInputBorderStyle("1px solid rgba(0, 0, 0, 0.05)");
      setInputBorderColor("#27A2DC");
    }
  };

  const showhandleCategoryClick = (category) => {
    if (category === "Другой") {
      setShowCustomInput(true);
    }
  };

  const handleApplyClick = (category) => {
    setIsModalOpen(false);
      setSelectedCategories([...selectedCategories, [customCategory]]);
    
    setHighlightedCategories([]);
    setCustomCategory('')
  };

  const handleResetClick = () => {
    setSelectedCategories([]);
    setIsModalOpen(false);
    setShowCustomInput(false);
    setHighlightedCategories([]);
    setCustomCategory("");
  };

  const handleClearCategory = () => {
    setHighlightedCategories('')
    setCustomCategory("");
    setSelectedCategories('');
    setShowCustomInput(false);
    setInputBackgroundColor("#FAFAFA");
    setInputBorderStyle("1px solid rgba(0, 0, 0, 0.05)");
    setInputBorderColor("#27A2DC");
  };

  const handleCustomInputChange = (e) => {
    setCustomCategory(e.target.value);
  };

  return (
    <div className={style.containerInput}>
      <div className={style.inputSelect}>
        <input
          className={style.select}
          placeholder="Выберете марки"
          type="text"
          value={Array.isArray(selectedCategories) ? selectedCategories.join(' ') : selectedCategories}
          onClick={() => setIsModalOpen(true)}
          readOnly
          required
          style={{
            backgroundColor: inputBackgroundColor,
            border: inputBorderStyle,
          }}
        />
        {!selectedCategories.length > 0 && (
          <button
            type="button"
            className={style.buttonOpen}
            onClick={() => setIsModalOpen(true)}
          >
            <img src={sort} alt="" />
          </button>
        )}
        {selectedCategories.length > 0 && (
          <button
            type="button"
            className={style.buttonClose}
            onClick={handleClearCategory}
          >
            <img src={close} alt="" />
          </button>
        )}
      </div>

      {isModalOpen && (
        <div className={style.modal}>
          <ul className={style.modalCategory}>
            {categories.map((category) => (
              <li
                key={category}
                onClick={() => handleCategoryClick(category)}
                style={{
                  backgroundColor: highlightedCategories.includes(category)
                    ? "#EAF6FC"
                    : "transparent",
                }}
              >
                {category}
              </li>
            ))}

            <li
              style={{
                backgroundColor: showCustomInput ? "#EAF6FC" : "transparent",
              }}
              onClick={() => showhandleCategoryClick("Другой")}
            >
              Другой
            </li>
          </ul>

          {showCustomInput && (
            <div>
              <input
                className={style.showInput}
                type="text"
                placeholder="Укажите бренд"
                value={customCategory}
                onChange={handleCustomInputChange}
              />
            </div>
          )}

          <button
            className={style.apply}
            type="button"
            onClick={handleApplyClick}
          >
            Применить
          </button>
          <button
            className={style.throwOff}
            type="button"
            onClick={handleResetClick}
          >
            Сбросить
          </button>
        </div>
      )}
    </div>
  );
}
