import React, { useState, useEffect } from "react";
import style from "./FormHome.module.scss";
import DropdownWithButtons from "../DropdownWithButtons";
import axios from "axios";
import InputMask from "react-input-mask"; // Добавлен импорт

const useValidation = (value, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectedCategoryError, setsSelectedCategoryError] = useState(false);
  const [inputValid, setInputValid] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "minLength":
          value.length < validations[validation]
            ? setMinLengthError(true)
            : setMinLengthError(false);
          break;
        case "isEmpty":
          value ? setEmpty(false) : setEmpty(true);
          break;
        case "maxLength":
          value.length > validations[validation]
            ? setMaxLengthError(true)
            : setMaxLengthError(false);
          break;
        case "isEmail":
          const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          re.test(String(value).toLowerCase())
            ? setEmailError(false)
            : setEmailError(true);
          break;
        case "isName":
          const reName = /^.+$/;
          reName.test(String(value).toLowerCase())
            ? setNameError(false)
            : setNameError(true);
          break;
        case "isPhone":
          const rePhone = /^\+\d{1} \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
          rePhone.test(String(value).toLowerCase())
            ? setPhoneError(false)
            : setPhoneError(true);

          break;
      }
    }
  }, [value]);

  useEffect(() => {
    if (
      selectedCategoryError ||
      phoneError ||
      isEmpty ||
      maxLengthError ||
      minLengthError ||
      emailError ||
      nameError
    ) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [
    selectedCategoryError,
    phoneError,
    isEmpty,
    maxLengthError,
    minLengthError,
    emailError,
    nameError,
  ]);

  return {
    selectedCategoryError,
    phoneError,
    nameError,
    isEmpty,
    minLengthError,
    emailError,
    maxLengthError,
    inputValid,
  };
};
const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setDirty] = useState(false);
  const valid = useValidation(value, validations);

  const onChange = (e) => {
    setDirty(true);
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange,
    isDirty,
    ...valid,
  };
};

export default function FormHome({ setShowConfirmationModal }) {
  const firstName = useInput("", { isEmpty: true, minLength: 2, isName: true });
  const email = useInput("", { isEmpty: true, minLength: 2, isEmail: true });
  const phoneNumber = useInput("", {
    isEmpty: true,
    minLength: 2,
    isPhone: true,
  });
  const [formData, setFormData] = useState({
    firstName: firstName.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    selectedCategory: [],
  });

  const getUTMParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const utmCampaign = params.get("utm_campaign");
    const utmContent = params.get("utm_content");
    const utmMedium = params.get("utm_medium");
    const utmSource = params.get("utm_source");
    const utmTerm = params.get("utm_term");
    return { utmCampaign, utmContent, utmMedium, utmSource, utmTerm };
  };

  const resetForm = () => {
    firstName.setValue("");
    email.setValue("");
    phoneNumber.setValue("");
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedCategory: "",
    }));
  };
  useEffect(() => {
    const { utmCampaign, utmContent, utmMedium, utmSource, utmTerm } =
      getUTMParams();

    setFormData((prevFormData) => ({
      ...prevFormData,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      utmTerm,
    }));
  }, []);

  const handleInputChange = (event) => {
    const formattedValue = formatPhoneNumber(event.target.value);
    phoneNumber.onChange({
      target: {
        value: formattedValue,
      },
    });
  };

  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, "");

    const matches = cleanedValue.match(
      /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/
    );

    if (matches) {
      const formattedValue = `+${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}-${matches[5]}`;
      return formattedValue.replace(/[\s()-]+$/, "");
    }

    return value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      fields: {
        TITLE: "Заявка с формы обратной связи CTRL",
        NAME: firstName.value,
        PHONE: [
          {
            VALUE: phoneNumber.value,
            VALUE_TYPE: "WORK",
          },
        ],
        EMAIL: [
          {
            VALUE: email.value,
            VALUE_TYPE: "WORK",
          },
        ],
        UF_CRM_1673362096: `Интересуют марки: ${formData.selectedCategory}`,

        ASSIGNED_BY_ID: 1,
        SOURCE_ID: "42",
        UTM_CAMPAIGN: formData.utmCampaign,
        UTM_CONTENT: formData.utmContent,
        UTM_MEDIUM: formData.utmMedium,
        UTM_SOURCE: formData.utmSource,
        UTM_TERM: formData.utmTerm,
      },
      params: {
        REGISTER_SONET_EVENT: "Y",
      },
    };
    const MyEmail = "Trucks@ctrl.lc";
    const subjectText = `Новое письмо с сайта CTRL`;
    const messageText = `${postData.fields.TITLE}\n\nИмя: ${postData.fields.NAME}\nТелефон: ${postData.fields.PHONE[0].VALUE}\nEmail: ${postData.fields.EMAIL[0].VALUE}\n\nUF_CRM_1673362096: ${postData.fields.UF_CRM_1673362096}\n\nASSIGNED_BY_ID: ${postData.fields.ASSIGNED_BY_ID}\nSOURCE_ID: ${postData.fields.SOURCE_ID}\nUTM_CAMPAIGN: ${postData.fields.UTM_CAMPAIGN}\nUTM_CONTENT: ${postData.fields.UTM_CONTENT}\nUTM_MEDIUM: ${postData.fields.UTM_MEDIUM}\nUTM_SOURCE: ${postData.fields.UTM_SOURCE}\nUTM_TERM: ${postData.fields.UTM_TERM}`;

    const apiEndpoint =
      "https://bitrix.ctrl.lc/rest/1187/t3jd7es2us54phek/crm.lead.add";
    axios.post("https://proxy.ctrl.lc:3001/api/v1/email/", {
      email: MyEmail,
      subject: subjectText,
      message: messageText,
    });
    axios
      .post(apiEndpoint, postData)
      .then((response) => {
        setShowConfirmationModal(true);
        resetForm();
        console.log("Успешно отправлено");
      })
      .catch((error) => {
        console.error("Ошибка при отправке запроса", error);
      });
  };

  return (
    <div className={style.form}>
      <form onSubmit={handleSubmit}>
        <h4 className={style.title}>
          Оставьте заявку и мы подберем лучшие предложения техники от дилеров
        </h4>
        <div className={style.form__container1}>
          <input
            className={` ${formData.name ? style.input__focused : style.name}`}
            type="text"
            name="name"
            placeholder="Имя*"
            onChange={(e) => firstName.onChange(e)}
            value={firstName.value}
          />

          <InputMask
            mask="+7 (999) 999-99-99"
            className={` ${
              formData.phone ? style.input__focused : style.phone
            }`}
            type="tel"
            name="phone"
            placeholder="Номер телефона*"
            value={phoneNumber.value}
            onChange={(e) => {
              phoneNumber.onChange(e);
              const cleanedValue = e.target.value.replace(/\D/g, "");

              handleInputChange({ target: { value: cleanedValue } });
            }}
          />
        </div>
        <div className={style.form__container2}>
          <input
            className={` ${
              formData.email ? style.input__focused : style.email
            }`}
            type="email"
            name="email"
            placeholder="E-mail"
            value={email.value}
            onChange={(e) => email.onChange(e)}
          />
          <DropdownWithButtons
            selectedCategories={formData.selectedCategory}
            setSelectedCategories={(value) =>
              setFormData({ ...formData, selectedCategory: value })
            }
          />
        </div>
        <button
          type="submit"
          disabled={
            !phoneNumber.inputValid ||
            !firstName.inputValid ||
            !formData.selectedCategory.length >= 1
          }
          className={style.button}
        >
          Отправить заявку
        </button>
        <p className={style.required}>* Поле обязательно для заполнения</p>
        <p className={style.personal}>
          Оставляя заявку, Вы соглашаетесь на{" "}
          <a href="https://ctrl.lc/polzovatelskoe_soglashenie" target="_blank">
            обработку персональных данных.
          </a>
        </p>
      </form>
    </div>
  );
}
