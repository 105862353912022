import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./Main.module.scss";
import logo from "../assets/Logo.svg";
import logo2 from "../assets/Logo2.svg";
import Conditions from "../assets/Conditions.png";
import Privilege from "../assets/Privilege.png";
import Line from "../assets/Line.png";
import HomeBG from "../assets/HomeBG.png";
import Ellipse1 from "../assets/Ellipse1.png";
import Ellipse2 from "../assets/Ellipse2.png";
import Ellipse3 from "../assets/Ellipse3.png";
import BgWork from "../assets/BgWork.png";
import Popup from "../assets/Popup.png";
import ClosePopup from "../assets/closePop.svg";
import FormHome from "./components/FormHome";
import { motion } from "framer-motion";
import InputMask from "react-input-mask"; // Добавлен импорт

const useValidation = (value, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectedCategoryError, setsSelectedCategoryError] = useState(false);
  const [inputValid, setInputValid] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "minLength":
          value.length < validations[validation]
            ? setMinLengthError(true)
            : setMinLengthError(false);
          break;
        case "isEmpty":
          value ? setEmpty(false) : setEmpty(true);
          break;
        case "maxLength":
          value.length > validations[validation]
            ? setMaxLengthError(true)
            : setMaxLengthError(false);
          break;
        case "isEmail":
          const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          re.test(String(value).toLowerCase())
            ? setEmailError(false)
            : setEmailError(true);
          break;
        case "isName":
          const reName = /^.+$/;
          reName.test(String(value).toLowerCase())
            ? setNameError(false)
            : setNameError(true);
          break;
        case "isPhone":
          const rePhone = /^\+\d{1} \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
          rePhone.test(String(value).toLowerCase())
            ? setPhoneError(false)
            : setPhoneError(true);

          break;
      }
    }
  }, [value]);

  useEffect(() => {
    if (
      selectedCategoryError ||
      phoneError ||
      isEmpty ||
      maxLengthError ||
      minLengthError ||
      emailError ||
      nameError
    ) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [
    selectedCategoryError,
    phoneError,
    isEmpty,
    maxLengthError,
    minLengthError,
    emailError,
    nameError,
  ]);

  return {
    selectedCategoryError,
    phoneError,
    nameError,
    isEmpty,
    minLengthError,
    emailError,
    maxLengthError,
    inputValid,
  };
};
const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setDirty] = useState(false);
  const valid = useValidation(value, validations);

  const onChange = (e) => {
    setDirty(true);
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange,
    isDirty,
    ...valid,
  };
};

export default function Main() {
  const firstName = useInput("", { isEmpty: true, minLength: 2, isName: true });
  const email = useInput("", { isEmpty: true, minLength: 2, isEmail: true });
  const phoneNumber = useInput("", {
    isEmpty: true,
    minLength: 2,
    isPhone: true,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: firstName.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    selectedCategory: [],
  });

  const getUTMParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const utmCampaign = params.get("utm_campaign");
    const utmContent = params.get("utm_content");
    const utmMedium = params.get("utm_medium");
    const utmSource = params.get("utm_source");
    const utmTerm = params.get("utm_term");
    return { utmCampaign, utmContent, utmMedium, utmSource, utmTerm };
  };
  const resetForm = () => {
    firstName.setValue("");
    email.setValue("");
    phoneNumber.setValue("");
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedCategory: "",
    }));
  };

  useEffect(() => {
    const { utmCampaign, utmContent, utmMedium, utmSource, utmTerm } =
      getUTMParams();

    setFormData((prevFormData) => ({
      ...prevFormData,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      utmTerm,
    }));
  }, []);

  const handleInputChange = (event) => {
    const formattedValue = formatPhoneNumber(event.target.value);
    phoneNumber.onChange({
      target: {
        value: formattedValue,
      },
    });
  };
  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, "");

    const matches = cleanedValue.match(
      /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/
    );

    if (matches) {
      const formattedValue = `+${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}-${matches[5]}`;
      return formattedValue.replace(/[\s()-]+$/, "");
    }

    return value;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      fields: {
        TITLE: "Заявка с формы обратной связи CTRL",
        NAME: firstName.value,
        PHONE: [
          {
            VALUE: phoneNumber.value,
            VALUE_TYPE: "WORK",
          },
        ],
        EMAIL: [
          {
            VALUE: email.value,
            VALUE_TYPE: "WORK",
          },
        ],
        ASSIGNED_BY_ID: 1,
        SOURCE_ID: "42",
        UTM_CAMPAIGN: formData.utmCampaign,
        UTM_CONTENT: formData.utmContent,
        UTM_MEDIUM: formData.utmMedium,
        UTM_SOURCE: formData.utmSource,
        UTM_TERM: formData.utmTerm,
      },
      params: {
        REGISTER_SONET_EVENT: "Y",
      },
    };
    const MyEmail = "Trucks@ctrl.lc";
    const subjectText = `Новое письмо с сайта CTRL`;
    const messageText = `${postData.fields.TITLE}\n\nИмя: ${postData.fields.NAME}\nТелефон: ${postData.fields.PHONE[0].VALUE}\nEmail: ${postData.fields.EMAIL[0].VALUE}\n\nUF_CRM_1673362096: ${postData.fields.UF_CRM_1673362096}\n\nASSIGNED_BY_ID: ${postData.fields.ASSIGNED_BY_ID}\nSOURCE_ID: ${postData.fields.SOURCE_ID}\nUTM_CAMPAIGN: ${postData.fields.UTM_CAMPAIGN}\nUTM_CONTENT: ${postData.fields.UTM_CONTENT}\nUTM_MEDIUM: ${postData.fields.UTM_MEDIUM}\nUTM_SOURCE: ${postData.fields.UTM_SOURCE}\nUTM_TERM: ${postData.fields.UTM_TERM}`;
    const apiEndpoint =
      "https://bitrix.ctrl.lc/rest/1187/t3jd7es2us54phek/crm.lead.add";
    axios.post("https://proxy.ctrl.lc:3001/api/v1/email/", {
      email: MyEmail,
      subject: subjectText,
      message: messageText,
    });
    axios
      .post(apiEndpoint, postData)
      .then((response) => {
        setShowConfirmationModal(true);
        console.log("Успешно отправлено");
        resetForm();
      })
      .catch((error) => {
        console.error("Ошибка при отправке запроса", error);
      });
  };
  const closeModal = () => {
    setShowConfirmationModal(false);
  };
  return (
    <>
      <div className={style.main}>
        <div className={style.wrapper}>
          <div className={style.homeBg}>
            <div className={style.home__container}>
              {showConfirmationModal && (
                <div className={style.ModalPop}>
                  <div className={style.ModalPop__container}>
                    <img src={Popup} alt="" />
                    <h3>Спасибо!</h3>
                    <p>
                      Ваша заявка принята! Наш менеджер свяжется с вами в
                      ближайшее время.
                    </p>
                    <button
                      className={style.ModalPop__button}
                      onClick={closeModal}
                    >
                      <img src={ClosePopup} alt="" />
                    </button>
                  </div>
                </div>
              )}
              <header className={style.header}>
                <div>
                  <a href="https://ctrl.lc/products/trucks" target="_blank">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className={style.contactContainer}>
                  <a
                    className={style.about}
                    href="https://ctrl.lc/company"
                    target="_blank"
                  >
                    О компании
                  </a>
                  <a className={style.tel} href="tel:+74951084788">
                    + 7 495 108 47 88
                  </a>
                </div>
              </header>
              <div className={style.home}>
                <motion.h1
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2 }}
                  viewport={{ once: true }}
                  className={style.title1}
                >
                  Купите новую
                  <br /> технику в лизинг,
                </motion.h1>
                <motion.h2
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2 }}
                  viewport={{ once: true }}
                  className={style.title2}
                >
                  с выгодой <span>500 000 руб.</span> до конца ноября
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2 }}
                  viewport={{ once: true }}
                  className={style.subtitle}
                >
                  Одобрение сделки за 24 часа
                </motion.p>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 4 }}
                  viewport={{ once: true }}
                  className={style.advantages}
                >
                  <p>Аванс от 0%</p>
                  <p>
                    Возможность сдать старую <br /> технику в trade in
                  </p>
                </motion.div>
              </div>
              <FormHome setShowConfirmationModal={setShowConfirmationModal} />
            </div>
          </div>
          <div className={style.container}>
            <div className={style.conditions}>
              <motion.div
                className={style.conditions__img}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
                viewport={{ once: true }}
              >
                <img src={Conditions} alt="" />
              </motion.div>
              <motion.div
                className={style.conditions__inform}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
                viewport={{ once: true }}
              >
                <h3 className={style.conditions__title}>
                  Условия финансирования
                </h3>
                <div className={style.pluses}>
                  <div>
                    <p>одобряем с</p>
                    <span>
                      Авансом <p>от</p> 0%
                    </span>
                  </div>
                  <div>
                    <img src={Line} alt="" />
                  </div>
                  <div>
                    <p>срок лизинга</p>
                    <span>до 6 лет</span>
                  </div>
                </div>
                <ul className={style.categoryPluses}>
                  <li>Одобряем с авансом от 0%</li>
                  <li>Финансируем ТС с пробегом (до 7 лет)</li>
                  <li>
                    Вся техника в наличии. Эксклюзивные позиции привезем на
                    заказ.
                  </li>
                  <li>
                    Минимум документов Высокий процент одобрения - 89% заявок
                  </li>
                  <li>Высокий процент одобрения - 89% заявок</li>
                  <li>Скорость на всех этапах сделки</li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          viewport={{ once: true }}
          className={style.privilege}
        >
          <div className={style.wrapper}>
            <div className={style.container}>
              <div className={style.wrapperInner}>
                <div className={style.privilege__container}>
                  <h3 className={style.privilege__title}>
                    Специальные условия на технику <span>Beiben</span>
                  </h3>
                  <p className={style.privilege__subtitle}>
                    Действует при отправке заявки до 25 ноября
                  </p>
                  <div className={style.privilege__item}>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="61"
                        viewBox="0 0 60 61"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_26_281)">
                          <mask
                            id="mask0_26_281"
                            maskUnits="userSpaceOnUse"
                            x="-3"
                            y="0"
                            width="60"
                            height="61"
                          >
                            <path
                              d="M-1.99982 59.4998V1.49993H56V59.4998H-1.99982Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="2"
                            />
                          </mask>
                          <g mask="url(#mask0_26_281)">
                            <path
                              d="M53.4376 31.9652C53.6624 32.5895 53.7773 33.2481 53.7773 33.9117V44.4211"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.1953 12.5768H41.6682C44.5418 12.5768 47.1067 14.3795 48.0801 17.0833"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M49.7253 21.6533L53.4376 31.9651C53.5479 32.2719 53.6319 32.5876 53.6887 32.9087H39.4584C36.3317 32.9087 33.7969 30.3739 33.7969 27.2471V12.5767H41.6682C44.5419 12.5767 47.1067 14.3794 48.08 17.0832"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.1953 7.53093H35.6142C37.6736 7.53093 39.4975 8.86054 40.1278 10.8213L40.6919 12.5767H22.1953"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.1953 42.0056V1.37871H-2.12102"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M-2.12102 52.0688H22.1953V47.0316"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M48.7704 52.0688H54.5864C55.434 52.0688 56.1211 51.3818 56.1211 50.5342V45.9559C56.1211 45.1083 55.434 44.4211 54.5864 44.4211H48.7704C47.9228 44.4211 47.2357 45.1083 47.2357 45.9559V50.5342C47.2357 51.3818 47.9228 52.0688 48.7704 52.0688Z"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M51.2196 41.0681H53.7205V36.242H48.3291C47.6891 36.242 47.2698 36.9118 47.5494 37.4876L48.4445 39.3308C48.6974 39.8516 49.0918 40.2907 49.5825 40.598C50.0733 40.9052 50.6406 41.0681 51.2196 41.0681Z"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M29.0721 51.9731C29.0721 56.1968 32.4991 59.6208 36.7266 59.6208C40.9541 59.6208 44.3811 56.1968 44.3811 51.9731C44.3811 47.7493 40.9541 44.3252 36.7266 44.3252C32.4991 44.3252 29.0721 47.7493 29.0721 51.9731Z"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M36.7265 55.9348C38.913 55.9348 40.6919 54.1576 40.6919 51.973C40.6919 49.7885 38.913 48.0111 36.7265 48.0111C34.54 48.0111 32.7611 49.7885 32.7611 51.973C32.7611 54.1576 34.54 55.9348 36.7265 55.9348Z"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_26_281">
                            <rect
                              width="60"
                              height="60"
                              fill="white"
                              transform="matrix(-1 0 0 1 60 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.privilege__text}>
                      <p>Скидка 500 000 на приобретение в лизинг</p>
                    </div>
                  </div>
                  <div className={style.privilege__item}>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="61"
                        viewBox="0 0 60 61"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_80_1495)">
                          <mask
                            id="mask0_80_1495"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="60"
                            height="61"
                          >
                            <path
                              d="M58.9998 59.5V1.50018H1V59.5H58.9998Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="2"
                            />
                          </mask>
                          <g mask="url(#mask0_80_1495)">
                            <path
                              d="M29.999 1.67205L54.3749 9.3089V29.8191C54.3749 44.2269 44.1485 56.6073 29.9999 59.3281C15.8513 56.6073 5.625 44.2269 5.625 29.8191V9.3089L30.0009 1.67205"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M45.0794 40.8015C46.83 37.5025 47.8124 33.7553 47.8124 29.8192V14.1301L29.9999 8.54953L12.1875 14.1301V29.8192C12.1875 40.6355 19.6057 50.0248 29.9999 52.6108C32.9692 51.8726 35.7646 50.5586 38.2277 48.7433"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <path
                            d="M35.3032 27.1073L27.1252 35.2852"
                            stroke="#27A2DC"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                          />
                          <path
                            d="M24 32.16L27.1251 35.2852"
                            stroke="#27A2DC"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                          />
                          <circle cx="42" cy="45.5" r="1" fill="#27A2DC" />
                        </g>
                        <defs>
                          <clipPath id="clip0_80_1495">
                            <rect
                              width="60"
                              height="60"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.privilege__text}>
                      <p>Гарантия от производителя 3 года (или 400 000км)</p>
                      <p>
                        Гарантия обратного выкупа по окончании срока лизинга
                      </p>
                    </div>
                  </div>
                  <div className={style.privilege__item}>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="61"
                        viewBox="0 0 60 61"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_26_252)">
                          <mask
                            id="mask0_26_252"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="60"
                            height="61"
                          >
                            <path
                              d="M58.9998 59.5V1.50017H1V59.5H58.9998Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="2"
                            />
                          </mask>
                          <g mask="url(#mask0_26_252)">
                            <path
                              d="M52.0987 11.8299C50.6342 13.2943 48.2599 13.2943 46.7954 11.8299C45.3309 10.3654 45.3309 7.99104 46.7954 6.52655C48.2599 5.06206 50.6342 5.06206 52.0987 6.52655C53.5632 7.99104 53.5632 10.3654 52.0987 11.8299Z"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.2046 54.4737C11.7402 55.9381 9.36583 55.9381 7.90134 54.4737C6.43686 53.0092 6.43686 50.6348 7.90134 49.1704C9.36583 47.7059 11.7402 47.7059 13.2046 49.1704C14.6691 50.6348 14.6691 53.0092 13.2046 54.4737Z"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M24.3147 58.7676C26.1866 59.1413 28.0909 59.3291 29.9998 59.3281C45.9211 59.3281 58.8278 46.4214 58.8278 30.5001C58.8278 23.5498 56.3678 17.1744 52.2716 12.1959"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M35.7397 2.24352C33.8503 1.86248 31.9274 1.67102 29.9999 1.672C14.0786 1.672 1.17188 14.5788 1.17188 30.5C1.17188 37.4503 3.63187 43.8257 7.72815 48.8042"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M30 39.0114C25.2994 39.0114 21.4887 35.2008 21.4887 30.5001C21.4887 25.7994 25.2994 21.9889 30 21.9889C34.7006 21.9889 38.5113 25.7994 38.5113 30.5001C38.5113 35.2008 34.7006 39.0114 30 39.0114ZM46.9728 26.8259C46.5336 24.782 45.7267 22.8348 44.5915 21.0793L46.8368 18.8338L41.6538 13.6545L39.4102 15.8981C37.6547 14.7629 35.7076 13.9561 33.6637 13.5169V10.3439H26.3362V13.5169C24.2923 13.9561 22.3452 14.7629 20.5897 15.8981L18.3462 13.6545L13.1631 18.8338L15.4084 21.0793C14.2732 22.8348 13.4664 24.782 13.0272 26.8259H9.84578L9.8418 34.1533H13.0271C13.4663 36.1972 14.2731 38.1444 15.4083 39.9L13.1544 42.1539L18.3338 47.337L20.5895 45.0812C22.3451 46.2164 24.2923 47.0233 26.3362 47.4625V50.6562H33.6637V47.4625C35.7077 47.0233 37.6549 46.2164 39.4105 45.0812L41.6662 47.337L46.8455 42.1539L44.5917 39.9C45.7268 38.1444 46.5337 36.1972 46.9729 34.1533H50.1582V26.8259H46.9728Z"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_26_252">
                            <rect
                              width="60"
                              height="60"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.privilege__text}>
                      <p>Специальный тариф по КАСКО, скидка до 100 000р.</p>
                    </div>
                  </div>
                  <div className={style.privilege__item}>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="61"
                        viewBox="0 0 60 61"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_26_299)">
                          <mask
                            id="mask0_26_299"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="60"
                            height="61"
                          >
                            <path
                              d="M58.9998 59.5V1.50018H1V59.5H58.9998Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="2"
                            />
                          </mask>
                          <g mask="url(#mask0_26_299)">
                            <path
                              d="M7.32148 36.0785L0.913218 18.2646C0.80318 17.9541 0.966656 17.6104 1.27708 17.497C4.66801 16.2789 8.20589 15.0875 11.7772 13.8862M45.7639 48.7031L25.2911 56.0616L16.3062 59.292C15.9959 59.4056 15.6521 59.242 15.5386 58.9317L8.66984 39.8262"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M49.8124 48.0089L28.552 51.6532L19.1399 53.2684C18.8162 53.3217 18.5025 53.1015 18.4457 52.7777L14.9713 32.5044L11.143 10.1851C11.116 10.0285 11.1521 9.8676 11.2434 9.73753C11.3347 9.60746 11.4738 9.51884 11.6303 9.49104L22.3574 7.65226"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.3572 25.9235V2.29608C22.3575 2.13066 22.4234 1.97212 22.5404 1.85517C22.6573 1.73821 22.8159 1.67237 22.9813 1.67206H51.3542C51.5444 1.67206 51.5978 1.74882 51.9216 2.05585L58.767 8.58341C59.044 8.85376 59.1208 8.88376 59.1208 9.06399V47.3816C59.1208 47.7253 58.8405 48.009 58.4968 48.009H22.9813C22.6376 48.009 22.3572 47.7253 22.3572 47.3816V30.0015"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M50.8872 1.67193L50.9006 7.86246C50.9006 9.24738 52.1689 9.16054 52.7795 9.16054H59.121"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M53.4804 14.4402H28.3516"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M53.4804 20.6841H28.3516"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M53.4804 26.928H28.3516"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M53.4804 33.1719H28.3516"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M53.4804 39.4158H28.3516"
                              stroke="#27A2DC"
                              strokeWidth="2"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_26_299">
                            <rect
                              width="60"
                              height="60"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={style.privilege__text}>
                      <p>Новая техника без пробега с НДС</p>
                    </div>
                  </div>
                  <p className={style.privilege__item__subtitle}>
                    Скидка 500 000 на приобретение в лизинг (предоставляется
                    через компенсацию процентной ставки по лизингу)*
                  </p>
                </div>
                <div className={style.privilege__img}>
                  <img src={Privilege} alt="" />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <div className={style.wrapper}>
          <div className={style.container}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
              className={style.HowItWorks}
            >
              <h3 className={style.HowItWorks__title}>
                <span>Новая возможность</span> сдайте старую технику в trade -
                in в качестве аванса
              </h3>
              <div className={style.HowItWorks__container}>
                <div className={style.HowItWorks__containerBg}></div>
                <div className={style.HowItWorks__text1}>
                  <p className={style.HowItWorks__text1__span}>/01</p>
                  <p className={style.HowItWorks__text1__p}>
                    Вы оставляете заявку и мы подбираем технику по Вашему
                    запросу
                  </p>
                </div>
                <div className={style.HowItWorks__text2}>
                  <p className={style.HowItWorks__text2__span}>/02</p>
                  <div>
                    <p className={style.HowItWorks__text2__p}>
                      При желании вы можете сдать свою старую технику в TRADE IN
                    </p>
                    <p className={style.HowItWorks__subtext}>
                      Ее стоимость будет зачтена в качестве аванса
                    </p>
                  </div>
                </div>
                <div className={style.HowItWorks__text3}>
                  <p className={style.HowItWorks__text3__span}>/03</p>
                  <p className={style.HowItWorks__text3__p}>
                    Вы согласуете КП и выбираете подходящий вариант
                    из предложенных условий финансирования
                  </p>
                </div>
                <div className={style.HowItWorks__text4}>
                  <p className={style.HowItWorks__text4__span}>/04</p>
                  <div>
                    <p className={style.HowItWorks__text4__p}>
                      Мы подписываем договор и в этот же день вы можете забрать
                      технику
                    </p>
                    <p className={style.HowItWorks__subtext}>
                      Подписание возможно в вашем городе в дилерском центре
                      Вашего города или по ЭДО
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
              className={style.contactUs}
            >
              <form onSubmit={handleSubmit} className={style.contactUs__form}>
                <h4 className={style.contactUs__title}>Оставьте заявку</h4>
                <h5 className={style.contactUs__subtitle}>
                  Менеджер свяжется с Вами для консультации
                </h5>
                <div className={style.contactUs__form__container}>
                  <input
                    className={` ${
                      formData.name
                        ? style.input__focused
                        : style.contactUs__name
                    }`}
                    type="text"
                    name="name"
                    placeholder="Имя*"
                    onChange={(e) => firstName.onChange(e)}
                    value={firstName.value}
                  />
                  <InputMask
                    mask="+7 (999) 999-99-99"
                    className={` ${
                      formData.phone
                        ? style.input__focused
                        : style.contactUs__phone
                    }`}
                    type="tel"
                    name="phone"
                    placeholder="Номер телефона*"
                    value={phoneNumber.value}
                    onChange={(e) => {
                      phoneNumber.onChange(e);
                      const cleanedValue = e.target.value.replace(/\D/g, "");

                      handleInputChange({ target: { value: cleanedValue } });
                    }}
                  />
                  <input
                    className={` ${
                      formData.email
                        ? style.input__focused
                        : style.contactUs__email
                    }`}
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    value={email.value}
                    onChange={(e) => email.onChange(e)}
                  />
                  <button
                    type="submit"
                    disabled={!phoneNumber.inputValid || !firstName.inputValid}
                    className={style.contactUs__button}
                  >
                    Оставить заявку
                  </button>
                </div>

                <p className={style.contactUs__required}>
                  * Поле обязательно для заполнения
                </p>
                <p className={style.contactUs__personal}>
                  Оставляя заявку, Вы соглашаетесь на{" "}
                  <a
                    href="https://ctrl.lc/polzovatelskoe_soglashenie"
                    target="_blank"
                  >
                    обработку персональных данных.
                  </a>
                </p>
              </form>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: true }}
              className={style.information}
            >
              <h4 className={style.information__subtitle}>
                Работайте с надежным финансовым партнером
              </h4>

              <div className={style.information__title}>
                <img src={logo2} alt="" />
                <h3>
                  Федеральная лизинговая компания
                  <br /> <span>№9 на рынке</span> автолизинга*
                </h3>
              </div>
              <div className={style.information__container}>
                <div className={style.information__item}>
                  <img src={Ellipse1} alt="" />
                  <p className={style.information__item__text1}>
                    48,1 <span>МЛРД руб.</span>
                  </p>
                  <p className={style.information__item__text2}>
                    лизинговый портфель
                  </p>
                </div>
                <div className={style.information__item}>
                  <img src={Ellipse2} alt="" />
                  <p className={style.information__item__text1}>21 250</p>
                  <p className={style.information__item__text2}>
                    ТС профинансировано
                    <br /> в 2022 году
                  </p>
                </div>
                <div className={style.information__item}>
                  <img src={Ellipse3} alt="" />
                  <p className={style.information__item__text1}>25 940</p>
                  <p className={style.information__item__text2}>
                    автомобилей в лизинговом
                    <br /> парке на 1 января 2023 года
                  </p>
                </div>
              </div>
              <p className={style.information__subtext}>
                *По данным рэнкинга Эксперт РА в 2022 году
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
